<template>

    <TicketItems my-tickets title="Meus chamados"/>

</template>

<script>

import TicketItems from '../../management/ticket/pages/ticket/TicketItems.vue';

export default {
    name: "UserMyTickets",
    components: {
        TicketItems
    }
}

</script>